// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-challenge-detail-js": () => import("./../src/templates/challengeDetail.js" /* webpackChunkName: "component---src-templates-challenge-detail-js" */),
  "component---src-templates-update-detail-js": () => import("./../src/templates/updateDetail.js" /* webpackChunkName: "component---src-templates-update-detail-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accelerate-js": () => import("./../src/pages/accelerate.js" /* webpackChunkName: "component---src-pages-accelerate-js" */),
  "component---src-pages-challenges-js": () => import("./../src/pages/challenges.js" /* webpackChunkName: "component---src-pages-challenges-js" */),
  "component---src-pages-form-js": () => import("./../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-updates-js": () => import("./../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */)
}

